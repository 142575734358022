import { Component, Input } from '@angular/core';
import { MetadataExportService } from '../metadata-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-metadata-export-upload',
  templateUrl: './metadata-export-upload.component.html',
  styleUrls: ['./metadata-export-upload.component.css'],
})
export class MetadataExportUploadComponent {
  @Input() uploadRequestId: string;
  @Input() settingsConfirmed: boolean;
  @Input() editMode: boolean;

  file: File;

  constructor(private metadataExportService: MetadataExportService, private snackBar: MatSnackBar) {}

  fileLoaded(event) {
    this.file = event.target.files[0];
  }

  uploadFile() {
    this.metadataExportService.uploadFile(this.uploadRequestId, this.file).subscribe(() => {
      this.snackBar.open('File uploaded - You can close this window', 'Close');
    });
  }
}
