import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryPrice, CountryPriceFields } from '../../../../../../../models/configuration/soup-configuration/file-properties-model';

@Component({
  selector: 'app-country-price-modal',
  templateUrl: './country-price-modal.component.html',
  styleUrls: ['./country-price-modal.component.css'],
})
export class CountryPriceModalComponent implements OnInit {
  countryPrice: CountryPrice;

  constructor(
    public dialogRef: MatDialogRef<CountryPriceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { countryPrice: CountryPrice; isEdit: boolean }
  ) {}

  ngOnInit(): void {
    this.countryPrice = { ...this.data.countryPrice };
    if (!this.countryPrice.fields) {
      this.countryPrice.fields = new CountryPriceFields();
    }
  }

  save(): void {
    this.dialogRef.close(this.countryPrice);
  }

  cancel(): void {
    this.dialogRef.close(null); // Chiude il dialog senza salvare
  }
}
