import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomMetaDataField, CustomMetadataImportConfiguration } from '@models/configuration/custom-metadata-field-model';
import { CustomMetadataService } from '../custom-metadata.service';

@Component({
  selector: 'app-edit-custom-metadata-field-modal',
  templateUrl: './edit-custom-metadata-field-modal.component.html',
  styleUrls: ['./edit-custom-metadata-field-modal.component.scss'],
})
export class EditCustomMetadataFieldModalComponent {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    public dialogRef: MatDialogRef<EditCustomMetadataFieldModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomMetaDataField,
    private customMetadataService: CustomMetadataService
  ) {}

  addTag(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = input.value;
    this.data.tags.push(value);
    input.value = '';
  }
  removeTag(index: number) {
    this.data.tags.splice(index, 1);
  }

  changedDataType(dataType: string) {
    if (dataType === 'STRING' || dataType === 'BOOLEAN') {
      this.data.importConfiguration = undefined;
    }
    if (dataType === 'INT' || dataType === 'DOUBLE') {
      this.data.importConfiguration = new CustomMetadataImportConfiguration();
    }
  }

  save() {
    this.customMetadataService.save(this.data).subscribe(() => this.dialogRef.close(true));
  }
}
