import { Any, JsonObject, JsonProperty } from 'json2typescript';

import { CustomProfit } from './custom-profit-model';
import { PublicationRuleStore } from './publication-rule-store-model';
import { UpdateCriteria } from '../update-criteria-model';
import { CustomFulfilmentLatency } from './custom-fulfilment-latency-model';
import { CustomShipmentClass } from './custom-shipment-class-model';
import { MarkusRule } from '@models/configuration/rules/markus-rule-model';
import { MarginParameters } from '@models/configuration/rules/pricing-strategy-model';

@JsonObject('PublicationRule')
export class PublicationRule extends MarkusRule {
  @JsonProperty('store', PublicationRuleStore, true) store: PublicationRuleStore = undefined;
  @JsonProperty('updateCriteria', UpdateCriteria, true) updateCriteria: UpdateCriteria = undefined;
  @JsonProperty('customProfits', [CustomProfit], true) customProfits: CustomProfit[] = [];
  @JsonProperty('active', Boolean, true) active = true;
  @JsonProperty('marketplaceSyncActive', Boolean, true) marketplaceSyncActive = true;
  @JsonProperty('marketplaceSyncCronExpression', String, true) marketplaceSyncCronExpression: string = undefined;
  @JsonProperty('requireStoreFees', Boolean, true) requireStoreFees = true;
  @JsonProperty('sumShippingCosts', Boolean, true) sumShippingCosts = true;

  @JsonProperty('fulfilmentLatency', Number, true) fulfilmentLatency: number = undefined;

  @JsonProperty('defaultNetworkCategory', String, true) defaultNetworkCategory: string = undefined;
  @JsonProperty('defaultVat', Number, true) defaultVat: number = undefined;

  @JsonProperty('alertReceivers', String, true) alertReceivers: string = undefined;

  @JsonProperty('id', String, true) id: string = undefined;

  @JsonProperty('categorySelection', String, true) categorySelection: 'MARKETPLACE' | 'ACCOUNT' = 'MARKETPLACE';

  @JsonProperty('customFulfilmentLatencies', [CustomFulfilmentLatency], true) customFulfilmentLatencies: CustomFulfilmentLatency[] = [];
  @JsonProperty('customShipmentClasses', [CustomShipmentClass], true) customShipmentClasses: CustomShipmentClass[] = [];

  @JsonProperty('competitivePricingFeatures', String, true) competitivePricingFeatures: 'NONE' | 'COMPETITION_DATA_ONLY' | 'ALL' = 'NONE';
  @JsonProperty('repricingStrategy', String, true) repricingStrategy: 'BUY_BOX_TARGETING' | 'LOWEST_PRICE_TARGETING' = undefined;
  @JsonProperty('disableNonCompetitivePublications', Boolean, true) disableNonCompetitivePublications = false;

  @JsonProperty('buyBoxMarkdown', Number, true) buyBoxMarkdown: number = undefined;
  @JsonProperty('buyBoxMarkup', Number, true) buyBoxMarkup: number = undefined;

  @JsonProperty('pendingUpdatePriority', Number, true) pendingUpdatePriority: number = undefined;
  @JsonProperty('maxBuyBoxDistance', Number, true) maxBuyBoxDistance: number = undefined;

  @JsonProperty('pricingParameters', Any, true) pricingParameters: any;

  @JsonProperty('useSourceMetadata', Boolean, true) useSourceMetadata = false;

  @JsonProperty('metadataLanguageCodes', [String], true) metadataLanguageCodes: string[] = undefined;
  @JsonProperty('metadataFields', [String], true) metadataFields: string[] = [];

  constructor() {
    super();
    this.pricingParameters = new MarginParameters();
  }
}
