import { JsonObject, JsonProperty } from 'json2typescript';
import { Store } from '../../marketplace/store-model';

@JsonObject('PublicationRuleStore')
export class PublicationRuleStore {
  @JsonProperty('storeId', Number, true) storeId: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('marketplaceName', String, true) marketplaceName: string = undefined;
  @JsonProperty('networkType', String, true) networkType: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('accountConnectionName', String, true) accountConnectionName: string = undefined;
  @JsonProperty('defaultVat', Number, true) defaultVat: number = undefined;
}

export const publicationRuleStoreFromFullStore = (s: Store): PublicationRuleStore => {
  const newStore = new PublicationRuleStore();
  newStore.storeId = s.id;
  newStore.name = s.name;
  newStore.code = s.code;
  newStore.marketplaceName = s.marketplace.name;
  newStore.currency = s.currency;
  newStore.country = s.country;
  newStore.networkType = s.account?.connection?.marketplaceConnectionType
    ? s.account?.connection?.marketplaceConnectionType
    : s.marketplace.network;
  newStore.accountConnectionName = s.account.connection.name;
  return newStore;
};
