import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isNilty } from '@core/utils.service';
import {
  ColumnWithNameAndTags,
  ColumnWithPositionAndName,
  ExportMetadataTemplate,
  ValidationRule,
} from '@models/export-metadata/metadata-export-model';
import { TemplateRenameModalComponent } from '@shared/export-template-name-modal/export-template-name-modal.component';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { MetadataExportService } from '../metadata-export.service';
import { EditColumnNameModalComponent } from './edit-column-name-modal/edit-column-name-modal.component';
import { EditPositionAndNameModalComponent } from './edit-position-name-modal/edit-position-name-modal.component';
import { EditRuleModalComponent } from './edit-rule-modal/edit-rule-modal.component';
import { ExportSelectColumnPositionModalComponent } from './export-select-column-position-modal/export-select-column-position-modal.component';

@Component({
  selector: 'app-metadata-export-template',
  templateUrl: './metadata-export-template.component.html',
  styleUrls: ['./metadata-export-template.component.scss'],
})
export class MetadataExportTemplateComponent implements OnInit {
  @Input() currentTemplate: ColumnWithPositionAndName[] = [];
  @Output() currentTemplateChange = new EventEmitter<ColumnWithPositionAndName[]>();

  availableColumns = [];
  columns = [];

  templates: ExportMetadataTemplate[] = [];
  chosenTemplate: ExportMetadataTemplate;
  newTemplateName = null;

  emptyColumn: ColumnWithNameAndTags = { name: '', tags: [] };

  inputValue = '';

  ruleTypes: string[];

  constructor(private metadataExportService: MetadataExportService, private dialog: MatDialog) {}

  ngOnInit() {
    this.metadataExportService.getColumns().subscribe((r) => {
      r.sort((a, b) => (a.name > b.name ? 1 : -1));
      r.forEach((it) => {
        const column = it;
        this.availableColumns.push(column);
        this.columns.push(column);
      });
    });

    this.metadataExportService.getTemplates().subscribe((r) => (this.templates = r));

    this.metadataExportService.getRuleTypes().subscribe((r) => (this.ruleTypes = r));
  }

  setTemplate(template: ExportMetadataTemplate | null) {
    this.chosenTemplate = template;
    this.currentTemplate = [...template.columns];
    this.columns = this.availableColumns.filter((c) => !this.currentTemplate.find((ct) => ct.column === c.column));
    this.currentTemplateChange.emit(this.currentTemplate);
  }

  eraseAll() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'All changes will be lost' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (!r) return;
        this.currentTemplate = [];
        this.columns = this.availableColumns;
        this.chosenTemplate = undefined;
      });
  }

  resetTemplate() {
    const templateName = this.chosenTemplate.templateName;
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'All changes will be lost' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (!r) return;
        this.currentTemplate = [];
        this.columns = this.availableColumns;
        this.chosenTemplate = undefined;
        this.setTemplate(this.templates.find((it) => it.templateName === templateName));
      });
  }

  openSaveModal() {
    this.dialog
      .open(TemplateRenameModalComponent, { data: { newTemplateName: this.newTemplateName } })
      .afterClosed()
      .subscribe((r) => {
        this.newTemplateName = r;
        if (!isNilty(this.newTemplateName)) {
          this.saveTemplate();
        }
      });
  }

  openRenameModal() {
    this.dialog
      .open(TemplateRenameModalComponent, { data: { newTemplateName: this.chosenTemplate.templateName } })
      .afterClosed()
      .subscribe((r) => {
        this.newTemplateName = r;
        if (!isNilty(this.newTemplateName)) {
          this.renameTemplate();
        }
      });
  }

  saveTemplate() {
    const newTemplate: ExportMetadataTemplate = {
      templateName: this.newTemplateName,
      columns: this.currentTemplate,
    };

    this.metadataExportService.saveTemplate(newTemplate).subscribe(() => {
      this.templates.push(newTemplate);
    });
  }

  renameTemplate() {
    this.metadataExportService.renameTemplate(this.chosenTemplate.id, this.newTemplateName).subscribe(() => {
      this.chosenTemplate.templateName = this.newTemplateName;
    });
  }

  deleteTemplate() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: `The template ${this.chosenTemplate.templateName} will be deleted` })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (!r) return;
        this.metadataExportService.deleteTemplate(this.chosenTemplate).subscribe(() => {
          this.templates.splice(this.templates.indexOf(this.chosenTemplate), 1);
          this.chosenTemplate = undefined;
        });
      });
  }

  addToTemplate(column: ColumnWithNameAndTags) {
    const newColumn = new ColumnWithPositionAndName(column.name, column.name, '');
    this.currentTemplate.push(newColumn);
    this.columns = this.columns.filter((it) => it !== column);
    if (column.name === '') this.editColumnPositionAndName(newColumn);
    else this.editColumnPosition(newColumn);
  }

  editColumnPosition(c: ColumnWithPositionAndName) {
    this.dialog
      .open(ExportSelectColumnPositionModalComponent, { data: { column: c, template: this.currentTemplate }, disableClose: true })
      .afterClosed()
      .subscribe((r) => {
        if (typeof r === 'number') this.removeFromTemplate(r);
        this.refreshTemplate();
      });
  }

  editColumnPositionAndName(c: ColumnWithPositionAndName) {
    this.dialog
      .open(EditPositionAndNameModalComponent, { data: { column: c, template: this.currentTemplate }, disableClose: true })
      .afterClosed()
      .subscribe((r) => {
        if (typeof r === 'number') this.removeFromTemplate(r);
        this.refreshTemplate();
      });
  }

  removeFromTemplate(index: number) {
    this.currentTemplate.splice(index, 1);
    this.columns = this.availableColumns.filter(
      (c) =>
        !this.currentTemplate.find((ct) => ct.column === c.column) && c.column.name.toLowerCase().includes(this.inputValue.toLowerCase())
    );
  }

  filterColumns(term: string) {
    this.inputValue = term;
    if (isNilty(term)) {
      this.columns = this.availableColumns.filter((c) => !this.currentTemplate.find((ct) => ct.column === c.column));
    } else {
      this.columns = this.availableColumns.filter(
        (c) =>
          !this.currentTemplate.find((ct) => ct === c) &&
          (c.name.toLowerCase().includes(term.toLowerCase()) || c.tags.join(' ').toLowerCase().includes(term.toLowerCase()))
      );
    }
  }

  editColumnName(c: ColumnWithPositionAndName) {
    this.dialog
      .open(EditColumnNameModalComponent, { data: { column: c }, disableClose: true })
      .afterClosed()
      .subscribe(() => {
        this.refreshTemplate();
      });
  }

  editRule(c: ColumnWithPositionAndName) {
    if (isNilty(c.validationRules)) c.validationRules = [new ValidationRule('', { value: '' })];
    this.dialog
      .open(EditRuleModalComponent, { data: { validationRules: c.validationRules, rules: this.ruleTypes }, disableClose: true })
      .afterClosed()
      .subscribe((r) => {
        c.validationRules = r;
      });
  }

  private refreshTemplate() {
    this.currentTemplate = this.currentTemplate.sort((a, b) => {
      const aSyllables = a.position.length;
      const bSyllables = b.position.length;
      if (aSyllables === bSyllables) {
        return a.position.localeCompare(b.position);
      }
      return aSyllables - bSyllables;
    });
  }
}
