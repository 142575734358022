import { Component, Input } from '@angular/core';
import { MetadataExportService } from '../metadata-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-metadata-export-by-eans',
  templateUrl: './metadata-export-by-eans.component.html',
  styleUrls: ['./metadata-export-by-eans.component.css'],
})
export class MetadataExportByEansComponent {
  @Input() requestId: string;
  @Input() settingsConfirmed: boolean;
  @Input() editMode: boolean;

  eans = '';

  constructor(private metadataExportService: MetadataExportService, private snackBar: MatSnackBar) {}

  clearEans() {
    this.eans = '';
  }

  exportByEans() {
    const formattedEans = this.eans.split(/['\s.,;\n']+/).filter((e) => e.trim());
    this.metadataExportService.exportByEans(this.requestId, formattedEans).subscribe(() => {
      this.snackBar.open('Export by EANs request has been sent - You can close this window', 'Close');
    });
  }
}
