import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public baseApiUrl = '';
  public ponchBaseApiUrl = '';
  public markusBaseApiUrl = '';
  public dev = true;
  public staging = false;
  public production = false;
  public logo = 'logo.svg';

  public getRestEndpoint(name: string): string {
    // tslint:disable-next-line: no-use-before-declare
    return this.baseApiUrl + zionRestEndpoints[name];
  }

  public getPonchRestEndpoint(name: string): string {
    // tslint:disable-next-line: no-use-before-declare
    return this.ponchBaseApiUrl + ponchRestEnpoints[name];
  }

  public getMarkusRestEndpoint(name: string): string {
    return this.markusBaseApiUrl + markusRestEndpoints[name];
  }
}

const ponchRestEnpoints = {
  userPermissions: `api/v1/user-permissions`,
  users: `api/v1/users`,
  roles: `api/v1/roles`,
  duplicateRoles: `api/v1/duplicate-roles`,
  permissions: `api/v1/permissions`,
  allPermissions: `api/v1/all-permissions`,
  applications: `api/v1/applications`,
};

const zionRestEndpoints = {
  // MARKETPLACES-STORES
  orderTypes: 'api/v1/commons/order-types',
  findStoresByFilters: 'api/v1/stores/find-by-filters',
  findAll: 'api/v1/stores/all',
  findStoresForView: 'api/v1/stores/view',
  owners: 'api/v1/owners',
  getMarketplaces: 'api/v1/marketplaces',
  sellingPlatforms: 'api/v1/selling-platforms',
  connections: 'api/v1/marketplace-connections',
  credentials: 'api/v1/marketplace-connections/get-credentials',
  saveCredentials: 'api/v1/marketplace-connections/save-credentials',
  accounts: 'api/v1/accounts',
  allAccountsNames: 'api/v1/accounts/all',
  storeGroups: 'api/v1/store-groups',
  getPublicationsJobs: 'api/v1/administration/publication-job-status',
  addNewStore: 'api/v1/stores/save',
  addNewMarketplace: 'api/v1/marketplaces/save',
  addNewStoreGroup: 'api/v1/store-groups/save',

  // SOUP CONFIGURATIONS
  soupParsers: 'api/v1/soup-configurations/parsers',
  soupDestinationClients: 'api/v1/soup-configurations/clients',
  soupValidFields: 'api/v1/soup-configurations/fields',
  soupConfigurations: 'api/v1/soup-configurations',
  saveSoupConfiguration: 'api/v1/soup-configurations/save',
  soupConfigurationExists: 'api/v1/soup-configurations/exists-distr-name',
  eanPredicates: 'api/v1/soup-configurations/ean-predicates',

  //SOUP ROUTE MANAGEMENT
  startRoute: 'api/v1/soup-route-management/start',
  stopRoute: 'api/v1/soup-route-management/stop',
  updateRoute: 'api/v1/soup-route-management/update',
  unlockRoute: 'api/v1/soup-route-management/unlock',

  // FTP ENDPOINTS
  ftpEndpoints: 'api/v1/ftp-endpoints',

  // CATALOGS
  suppliers: 'api/v1/suppliers',
  supplierDiscounts: 'api/v1/suppliers/discounts/',
  supplierInvoice: 'api/v1/suppliers/invoice/',
  stockItems: 'api/v1/stock-items',
  uploadStockItems: 'api/v1/stock-items/upload-file',
  uploadTransferStockItems: 'api/v1/stock-items/upload-transfer-file',
  catalogItems: 'api/v1/catalog-items',
  uploadCatalogItems: 'api/v1/catalog-items/upload-file',
  changeCatalogItemStatus: 'api/v1/catalog-items/change-status',
  stockLocations: 'api/v1/stock-locations',
  importStockLocations: 'api/v1/import-stock-locations',
  productBarcodes: 'api/v1/product-barcodes',
  catalogHistory: 'api/v1/catalog-item-history/',
  importStockLocationFeed: 'api/v1/import-stock-location-feed/',
  products: 'api/v1/products',
  productMetadataDetails: 'api/v1/product-metadata/details/',
  productsEquivalence: 'api/v1/products/equivalence',
  uploadEquivalenceProductsFile: 'api/v1/products/upload-equivalence/',
  productMetadata: 'api/v1/product-metadata',
  uploadNetworkCategoriesFile: 'api/v1/network-categories/upload/',

  // IMPORT-EXPORT
  importTemplates: 'api/v1/import/templates',
  requestImport: 'api/v1/import/request-file-upload/',
  uploadImportFile: 'api/v1/import/upload-file/',

  importProductFilesZip: 'api/v1/import/upload-file/product-files-zip/',
  importProductFilesAvailableFileTypes: 'api/v1/import/upload-file/product-files-zip/available-file-types',

  metadataExport: 'api/v1/export-metadata/',

  customMetadataFields: 'api/v1/custom-metadata',

  // PRODUCTS
  searchBarcodes: 'api/v1/product-metadata/search',
  searchBrands: 'api/v1/brands/search',
  productNetworkCategories: 'api/v1/products/network-categories/',
  addNewProduct: 'api/v1/products',
  brands: 'api/v1/brands',
  metadataPriority: 'api/v1/product-metadata-priority/',
  sourceTypes: 'api/v1/source-types/',
  deleteImagineUrl: 'api/v1/products/deleteProductImage',

  // CURRENCY
  currencies: 'api/v1/currencies',

  // COMMONS
  getLogisticPartners: 'api/v1/commons/logistic-partners',
  getNetworks: 'api/v1/commons/networks',
  getNetworkCategories: 'api/v1/commons/network-categories',
  languages: 'api/v1/commons/languages',

  // ADMINISTRATION
  getSoupFeedsStatus: 'api/v1/administration/soup-feeds-status',
  skipSupplierFeedsKeys: 'api/v1/administration/soup-feeds-status/skip',

  // EXCLUSIONS
  exclusions: 'api/v1/commons/exclusions',

  // EXCHANGE RATES
  exchangeRates: 'api/v1/exchange-rates',
  ukExchangeRates: 'api/v1/uk-gov-exchange-rates',

  // CATEGORIES
  categories: 'api/v1/categories/all',

  // MEASURE UNITS
  measureUnits: 'api/v1/commons/measure-units',

  // COUNTRIES
  isValidCountry: 'api/v1/countries/validate-country-code/',
  countries: 'api/v1/stores/find-all-countries',
};

const markusRestEndpoints = {
  publications: 'api/v1/publications',
  publicationsEnums: 'api/v1/publications/enums',
  publishedStores: 'api/v1/publications/published-stores',
  publicationsByStore: 'api/v1/publications/by-store/',
  publicationsByBarcode: 'api/v1/publications/by-barcode/',
  publicationCountByStore: 'api/v1/publications/count/by-store/',
  refreshPublication: 'api/v1/publications/refresh-publication',
  exportPublications: 'api/v1/publications/export-from-store/',
  publicationsAnomalouslyPriced: 'api/v1/publications/anomalously-priced-csv',
  publicationMetrics: 'api/v1/publication-metrics',
  promoPrices: 'api/v1/promo-prices/find-by-filters',
  promoPricesImport: 'api/v1/promo-prices/import',
  promoPriceDelete: 'api/v1/promo-prices/delete',

  findStoreFeesConfigurations: 'api/v1/store-fees/find',
  findStoreFeesByCode: 'api/v1/store-fees/by-code/',
  saveStoreFees: 'api/v1/store-fees/save',
  marketplaceCategoryFees: 'api/v1/category-fees/marketplace/find',
  saveMarketplaceCategoryFees: 'api/v1/category-fees/marketplace/save',
  networkCategoryFees: 'api/v1/category-fees/network/find',
  saveNetworkCategoryFees: 'api/v1/category-fees/network/save',

  // PUBLICATION_RULES
  publicationRules: 'api/v1/publication-rules',
  findRulesByFilters: 'api/v1/publication-rules/find-by-filters',
  rulePredicateFields: 'api/v1/publication-rules/predicate-fields',
  savePublicationRule: 'api/v1/publication-rules/save',
  unpublishRule: 'api/v1/publication-rules/unpublish',
  refreshRule: 'api/v1/publication-rules/refresh-rule',
  partiallyRefreshRule: 'api/v1/publication-rules/partially-refresh-rule',

  fullBruteRefresh: 'api/v1/publication-rules/full-brute-refresh-publication',
  partialBruteRefresh: 'api/v1/publication-rules/partial-brute-refresh-publication',

  // POWER_SELLER_RULES
  powerSellers: 'api/v1/power-sellers',
  powerSellerRules: 'api/v1/power-seller-rules',
  refreshPowerSellerRule: 'api/v1/power-seller-rules/refresh-rule',
  partiallyRefreshPowerSellerRule: 'api/v1/power-seller-rules/partially-refresh-rule',

  // BLACKLISTS
  blacklistSolve: 'api/v1/blacklists/solve',
  blacklistReasons: 'api/v1/blacklists/reasons',
  blacklistEnums: 'api/v1/blacklists/enums',
  blacklistBarcode: 'api/v1/blacklists/barcode',
  blacklistPredicates: 'api/v1/blacklists/predicates',
  blacklistWords: 'api/v1/blacklists/words',
  blacklistPredicatesByTargets: 'api/v1/blacklists/predicates/by-targets',
  blacklistWordsByTargets: 'api/v1/blacklists/words/by-targets',
  blacklistBarcodeByFilters: 'api/v1/blacklists/barcode/by-filters',
  importBlacklistFile: 'api/v1/blacklists/import',

  // RESTRICTIONS
  allowPlatformsRestriction: 'api/v1/restrictions/allow',
  deniedPlatformsRestriction: 'api/v1/restrictions/deny',

  saveBarcodeAllowedPlatforms: 'api/v1/restrictions/allow/barcode',
  savePredicatesAllowedPlatforms: 'api/v1/restrictions/allow/predicates',
  saveWordsAllowedPlatforms: 'api/v1/restrictions/allow/words',

  saveBarcodeDeniedPlatforms: 'api/v1/restrictions/deny/barcode',
  savePredicatesDeniedPlatforms: 'api/v1/restrictions/deny/predicates',
  saveWordsDeniedPlatforms: 'api/v1/restrictions/deny/words',

  predicatesAllowedPlatformsByTargets: 'api/v1/restrictions/allow/predicates/by-targets',
  wordsAllowedPlatformsByTargets: 'api/v1/restrictions/allow/words/by-targets',
  barcodeAllowedPlatformsByFilters: 'api/v1/restrictions/allow/barcode/by-filters',

  predicatesDeniedPlatformsByTargets: 'api/v1/restrictions/deny/predicates/by-targets',
  wordsDeniedPlatformsByTargets: 'api/v1/restrictions/deny/words/by-targets',
  barcodeDeniedPlatformsByFilters: 'api/v1/restrictions/deny/barcode/by-filters',

  // SALES ORDER POLL CONFIGURATION
  salesOrderPollConfiguration: 'api/v1/sales-order-poll-configuration',
  salesOrderPollConfigurationByFilters: 'api/v1/sales-order-poll-configuration/find-by-filters',
  saveSalesOrderPollConfiguration: 'api/v1/sales-order-poll-configuration/save',

  // COMPETITION DATA
  competitionData: 'api/v1/competition-data',
  sellingCompetitors: 'api/v1/selling-competitors',

  // MARKUS-CONFIGURATION
  markusConfiguration: 'api/v1/markus-configuration',

  // SOURCES
  publishedSources: 'api/v1/rules/published-sources',
};
