import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '@core/utils.service';

@JsonObject('RuleTypes')
export class RuleTypes {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('valueType', String, true) valueType: string = undefined;
}

@JsonObject('ValidationRule')
export class ValidationRule {
  @JsonProperty('ruleType', String, true) ruleType: string = undefined;
  @JsonProperty('ruleValue', { value: String }, true) ruleValue: { value: string | number } = undefined;

  constructor(ruleType: string, ruleValue: { value: string | number }) {
    this.ruleType = ruleType;
    this.ruleValue = ruleValue;
  }
}

@JsonObject('ColumnWithNameAndTags')
export class ColumnWithNameAndTags {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('tags', [String], true) tags: string[] = undefined;
  @JsonProperty('translatable', Boolean, true) translatable: boolean = undefined;
}

@JsonObject('ColumnWithPositionAndName')
export class ColumnWithPositionAndName {
  @JsonProperty('column', String, true) column: string = undefined;
  @JsonProperty('columnName', String, true) columnName: string = undefined;
  @JsonProperty('position', String, true) position: string = undefined;
  @JsonProperty('mandatory', Boolean, true) mandatory = false;
  @JsonProperty('validationRules', [ValidationRule], true) validationRules: ValidationRule[] = undefined;

  constructor(column: string, columnName: string, position: string) {
    this.column = column;
    this.columnName = columnName;
    this.position = position;
  }
}

@JsonObject('ExportMetadataTemplate')
export class ExportMetadataTemplate {
  @JsonProperty('id', String, true) id?: string = undefined;
  @JsonProperty('templateName', String, true) templateName: string = undefined;
  @JsonProperty('columns', [ColumnWithPositionAndName], true) columns: ColumnWithPositionAndName[] = undefined;
}

export const filterColumnWithNameAndTag = (
  allColumns: ColumnWithNameAndTags[],
  alreadySelectedColumns: string[],
  term: string
): ColumnWithNameAndTags[] => {
  if (isNilty(term)) {
    return allColumns.filter((c) => !alreadySelectedColumns.find((sc) => sc === c.name));
  } else {
    return allColumns.filter(
      (c) =>
        !alreadySelectedColumns.find((sc) => sc === c.name) &&
        (c.name.toLowerCase().includes(term.toLowerCase()) || c.tags.join(' ').toLowerCase().includes(term.toLowerCase()))
    );
  }
};
