import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNilty } from '@core/utils.service';
import {
  buildEmptyImportTemplate,
  duplicateImportTemplate,
  GenericImportTemplate,
  ImportTemplates,
  TemplateColumn,
  TemplateColumnWithPosition,
} from '@models/import/import-templates-model';
import { Language } from '@models/language-model';
import { Supplier } from '@models/supplier-model';
import { CommonsService } from '@shared/commons.service';
import { TemplateRenameModalComponent } from '@shared/export-template-name-modal/export-template-name-modal.component';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuppliersService } from '../../../suppliers/suppliers.service';
import { GenericImportService } from './generic-import.service';
import { InsertTemplateNameModalComponent } from './insert-template-name-component/insert-template-name-modal.component';
import { SelectColumnPositionModalComponent } from './select-column-position-modal/select-column-position-modal.component';

@Component({
  selector: 'app-generic-import',
  templateUrl: './generic-import.component.html',
  styleUrls: ['./generic-import.component.scss'],
})
export class GenericImportComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileElementRef: ElementRef;
  file: File;

  fileFormat: 'EXCEL' | 'CSV' = 'EXCEL';
  translators: string[] = ['AWS_TRANSLATE', 'DEEPL'];
  translatorAgent = '';

  language = 'EN';
  requestedTranslations: Language[] = [];

  templates: ImportTemplates[] = [];

  currentTemplateType: ImportTemplates;
  currentTemplate: GenericImportTemplate;
  allAvailableColumns: TemplateColumn[];
  filteredAvailableColumns: TemplateColumn[];

  newTemplateName = null;

  sourceRequired = true;

  validTemplate = false;

  sourceType: string;
  sourceId: string;
  sourceTypes: string[];

  suppliers: Supplier[] = [];

  languages: Language[] = [];

  filterString: string;

  private uploadRequestId: string;
  private maxVisibleAvailableColumns = 30;

  constructor(
    private dialog: MatDialog,
    private genericImportService: GenericImportService,
    private snackBar: MatSnackBar,
    private supplierService: SuppliersService,
    private commonsService: CommonsService
  ) {}

  ngOnInit() {
    this.refreshPage(undefined, undefined);
    this.genericImportService.getsourceTypes().subscribe((r) => (this.sourceTypes = r));
    this.supplierService.getAllSuppliers().subscribe((r) => (this.suppliers = r));
    this.commonsService.getLanguages().subscribe((r) => (this.languages = r));
  }

  importTypeSelected(t: ImportTemplates) {
    this.currentTemplateType = t;
  }

  createEmptyTemplate() {
    this.askForConfirmation().subscribe((r) => {
      if (r) {
        this.chooseTemplate(buildEmptyImportTemplate(this.currentTemplateType));
      }
    });
  }

  templateSelected(t: GenericImportTemplate) {
    this.askForConfirmation().subscribe((r) => {
      if (r) {
        this.chooseTemplate(t);
      }
    });
  }

  restoreDefault() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'All changes will be lost' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (!r) return;
        if (isNilty(this.currentTemplate.templateName)) {
          this.createEmptyTemplate();
        } else {
          const selectedTemplate = this.currentTemplateType.templates.find((it) => it.templateName === this.currentTemplate.templateName);
          this.chooseTemplate(selectedTemplate);
        }
      });
  }

  eraseAll() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'All changes will be lost' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (!r) return;
        this.currentTemplate.columns = this.currentTemplate.columns.filter((it) => it.mandatory);
        this.computeAvailableColumns();
      });
    this.validTemplate = false;
  }

  removeFromTemplate(c: TemplateColumnWithPosition, index: number) {
    if (!this.currentTemplate.editable) return;
    if (c.mandatory) return;

    this.currentTemplate.columns.splice(index, 1);
    this.allAvailableColumns.push(c.column);
    this.sortAndFilterColumns(this.allAvailableColumns);
    this.checkValidity();
  }

  addToTemplate(c: TemplateColumn) {
    if (!this.currentTemplate.editable) return;
    const newColumn = new TemplateColumnWithPosition(c, '', false, false);
    this.currentTemplate.columns.push(newColumn);
    this.computeAvailableColumns();
    this.editColumnPosition(newColumn);
  }

  editColumnPosition(c: TemplateColumnWithPosition) {
    if (!this.currentTemplate.editable) return;
    this.dialog
      .open(SelectColumnPositionModalComponent, { data: { column: c, template: this.currentTemplate }, disableClose: true })
      .afterClosed()
      .subscribe((r) => {
        if (typeof r === 'number') this.removeFromTemplate(c, r);
        this.refreshTemplate();
      });
  }

  saveTemplate() {
    if (this.currentTemplate.id) {
      this.callSaveTemplateAndRefreshPage();
      return;
    }
    this.dialog
      .open(InsertTemplateNameModalComponent)
      .afterClosed()
      .subscribe((name: string) => {
        if (isNilty(name)) return;
        this.currentTemplate.templateName = name;
        this.callSaveTemplateAndRefreshPage();
      });
  }

  openRenameModal() {
    this.dialog
      .open(TemplateRenameModalComponent, { data: { newTemplateName: this.currentTemplate.templateName } })
      .afterClosed()
      .subscribe((r) => {
        this.newTemplateName = r;
        if (!isNilty(this.newTemplateName)) {
          this.renameTemplate();
        }
      });
  }

  renameTemplate() {
    this.genericImportService.renameTemplate(this.currentTemplate.id, this.newTemplateName).subscribe(() => {
      this.currentTemplate.templateName = this.newTemplateName;
    });
  }

  deleteTemplate() {
    if (!this.currentTemplate.id) return;
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `Deleting ${this.currentTemplate.importType} template ${this.currentTemplate.templateName}.`,
      })
      .afterClosed()
      .subscribe((r) => {
        if (!r) return;
        this.genericImportService.deleteTemplate(this.currentTemplate).subscribe(() => {
          this.currentTemplate = undefined;
          this.refreshPage(this.currentTemplateType.importType, undefined);
        });
      });
  }

  confirm() {
    this.genericImportService
      .requestImport(
        this.currentTemplateType.importType,
        this.fileFormat,
        this.currentTemplate.columns,
        this.sourceType,
        this.sourceId,
        this.language,
        this.requestedTranslations.map((it) => it.code),
        this.translatorAgent
      )
      .subscribe((r) => {
        if (isNilty(r)) {
          this.dialog.open(GenericErrorModalComponent, { data: 'It was not possible to complete the request.' });
          return;
        }
        this.uploadRequestId = r;
        this.fileElementRef.nativeElement.click();
      });
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    this.genericImportService.uploadFile(this.uploadRequestId, this.file).subscribe(() => {
      this.snackBar.open('Your file has been uploaded')._dismissAfter(3000);
      this.file = undefined;
      this.currentTemplate = undefined;
    });
    this.fileElementRef.nativeElement.value = '';
  }

  addTranslation(event: MatSelectChange) {
    if (!event.value) return;
    if (this.requestedTranslations.find((l) => l.code === event.value) !== undefined) return;
    const selected = this.languages.find((it) => event.value === it.code);
    if (!selected) return;

    this.requestedTranslations.push(selected);
    event.source.writeValue(undefined);
  }
  removeTranslation(index: number) {
    this.requestedTranslations.splice(index, 1);
    if (this.requestedTranslations.length === 0) {
      this.translatorAgent = '';
    }
  }

  filterColumns(filterString: string) {
    this.filterString = filterString;
    this.sortAndFilterColumns(this.allAvailableColumns);
  }

  private chooseTemplate(t: GenericImportTemplate) {
    this.currentTemplate = duplicateImportTemplate(t, this.currentTemplateType);
    this.sourceRequired = t.importType !== 'PRODUCT_VARIANTS';
    this.computeAvailableColumns();
  }

  private callSaveTemplateAndRefreshPage() {
    this.genericImportService.saveTemplate(this.currentTemplate).subscribe(() => {
      const selectedTemplateType = this.currentTemplateType.importType;
      const selectedTemplateName = this.currentTemplate.templateName;
      this.refreshPage(selectedTemplateType, selectedTemplateName);
    });
  }

  private refreshPage(selectedImportType?: string, selectedTemplateName?: string) {
    this.genericImportService.getTemplates().subscribe((r) => {
      this.templates = r;
      this.currentTemplate = undefined;

      if (selectedImportType) {
        this.importTypeSelected(r.find((it) => it.importType === selectedImportType));
      }
      if (selectedTemplateName) {
        this.templateSelected(this.currentTemplateType.templates.find((it) => it.templateName === selectedTemplateName));
      }
    });
  }

  private computeAvailableColumns() {
    const allTemplateColumns = [...this.currentTemplateType.availableColumns, ...this.currentTemplateType.mandatoryColumnsForImportType];
    const alreadySelected = this.currentTemplate.columns.map((it) => it.column);
    this.sortAndFilterColumns(allTemplateColumns.filter((available) => isNilty(alreadySelected.find((it) => it.name === available.name))));
    this.checkValidity();
  }

  private sortAndFilterColumns(columns: TemplateColumn[]) {
    this.allAvailableColumns = columns.sort((a, b) => (a.name > b.name ? 1 : -1));

    if (isNilty(this.filterString)) {
      this.filteredAvailableColumns = this.allAvailableColumns.slice(0, this.maxVisibleAvailableColumns);
    } else {
      this.filteredAvailableColumns = this.allAvailableColumns
        .filter(
          (c) =>
            c.name.toUpperCase().indexOf(this.filterString.toUpperCase()) !== -1 ||
            c.description.toUpperCase().indexOf(this.filterString.toUpperCase()) !== -1 ||
            c.tags.some((t) => t.toUpperCase().indexOf(this.filterString.toUpperCase()) !== -1)
        )
        .slice(0, this.maxVisibleAvailableColumns);
    }
  }

  private refreshTemplate() {
    this.currentTemplate.columns = this.currentTemplate.columns.sort((a, b) =>
      this.computeColumnOrdinalNumber(a.position) > this.computeColumnOrdinalNumber(b.position) ? 1 : -1
    );
    this.checkValidity();
  }

  private computeColumnOrdinalNumber(columnPosition: string): number {
    const start = 96; // So that "a" is 0
    const length = columnPosition.length;

    return [...columnPosition.toLowerCase()].reduce((out: number, char, position) => {
      const val = char.charCodeAt(0) - start;
      const pow = Math.pow(26, length - position - 1);
      return out + val * pow;
    }, 0);
  }

  private checkValidity() {
    // all mandatory columns must be in the template
    const missingMandatoryColumn = this.currentTemplateType.mandatoryColumnsForImportType.find(
      (mandatoryColumn) => !this.currentTemplate.columns.find((c) => c.column.name === mandatoryColumn.name)
    );
    if (!isNilty(missingMandatoryColumn)) {
      this.validTemplate = false;
      return;
    }

    // At least one non-mandatory column must be in the template
    const nonMandatoryColumn = this.currentTemplate.columns.find((it) => !it.mandatory);
    if (isNilty(nonMandatoryColumn)) {
      this.validTemplate = false;
      return;
    }

    const missingPosition = this.currentTemplate.columns.find((it) => isNilty(it.position));
    if (!isNilty(missingPosition)) {
      this.validTemplate = false;
      return;
    }

    this.validTemplate = true;
  }

  private askForConfirmation(): Observable<boolean> {
    if (isNilty(this.currentTemplate)) return of(true);
    if (!isNilty(this.currentTemplate)) {
      return this.dialog
        .open(GenericConfirmationModalComponent, { data: 'You will lose all the changes made to the current template.' })
        .afterClosed()
        .pipe(map((r: boolean) => r));
    }
  }
}
