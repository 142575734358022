import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SoupConfiguration } from '@models/configuration/soup-configuration/soup-configuration-model';
import { SoupConfigurationService } from '../../soup-configuration.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CommonsService } from '@shared/commons.service';
import { MeasureUnits } from '@models/measure-units-model';
import { Language } from '@models/language-model';

@Component({
  selector: 'app-soup-supplier-configuration',
  templateUrl: './soup-supplier-configuration.component.html',
  styleUrls: ['./soup-supplier-configuration.component.css'],
})
export class SoupSupplierConfigurationComponent implements OnInit {
  @Input() soupConfiguration: SoupConfiguration;

  @Input() editMode: boolean;

  @ViewChild('distrInput', { static: true }) distrInput: ElementRef;

  validDistrName: boolean;

  massUnitMeasures: string[] = [];
  lengthUnitMeasures: string[] = [];
  sourceTypes: string[];
  languages: Language[];

  constructor(private soupConfigurationService: SoupConfigurationService, commonsService: CommonsService) {
    commonsService.getMeasureUnits().subscribe((r: MeasureUnits) => {
      this.massUnitMeasures = r.mass;
      this.lengthUnitMeasures = r.length;
    });
    commonsService.getLanguages().subscribe((r) => (this.languages = r));
  }

  ngOnInit(): void {
    if (!this.editMode) {
      fromEvent(this.distrInput.nativeElement, 'keyup')
        .pipe(
          map((event: any) => event.target.value),
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.soupConfigurationService
            .existsByDistrName(this.soupConfiguration.distrName)
            .subscribe((resp: boolean) => (this.validDistrName = !resp));
        });
    }
    this.soupConfigurationService.getsourceTypes().subscribe((r) => (this.sourceTypes = r));
  }
}
