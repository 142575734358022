import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CountryPriceFields')
export class CountryPriceFields {
  @JsonProperty('SHIPPING_COST', String, true)
  SHIPPING_COST: string = undefined;
  @JsonProperty('LATENCY', String, true)
  LATENCY: string = undefined;
  @JsonProperty('PRICE', String, true)
  PRICE: string = undefined;
  @JsonProperty('CURRENCY', String, true)
  CURRENCY: string = undefined;
}

@JsonObject('CountryPrice')
export class CountryPrice {
  @JsonProperty('countryCode', String, true)
  countryCode: string = undefined;

  @JsonProperty('fields', CountryPriceFields, true)
  fields: CountryPriceFields = new CountryPriceFields();
}

@JsonObject('ValuePair')
export class ValuePair {
  @JsonProperty('fromValue', String, true)
  fromValue: string = undefined;

  @JsonProperty('toValue', String, true)
  toValue: string = undefined;
}

@JsonObject('ColumnsPair')
export class ColumnsPair {
  @JsonProperty('fromColumn', String, true)
  fromColumn: string = undefined;

  @JsonProperty('toColumn', String, true)
  toColumn: string = undefined;

  @JsonProperty('useAsRegex', Boolean, true)
  useAsRegex: boolean = undefined;

  @JsonProperty('values', [ValuePair], true)
  values: ValuePair[] = [];

  constructor() {
    this.values = [];
  }
}

@JsonObject('FileProperties')
export class FileProperties {
  @JsonProperty('fileExtension', String, true)
  fileExtension: string = undefined;

  @JsonProperty('positions', Any, true)
  positions: any = {};

  @JsonProperty('alternativePositions', Any, true)
  alternativePositions: any = {};

  @JsonProperty('countryPrices', [CountryPrice], true)
  countryPrices: CountryPrice[] = [];

  @JsonProperty('mandatoryFields', [String], true)
  mandatoryFields: string[] = [];

  @JsonProperty('sourceFileSeparator', String, true)
  sourceFileSeparator = ';';

  @JsonProperty('textDelimiter', String, true)
  textDelimiter = '';

  @JsonProperty('decimalSeparator', String, true)
  decimalSeparator = '.';

  @JsonProperty('groupingSeparator', String, true)
  groupingSeparator = ' ';

  @JsonProperty('currencyConverter', Boolean, true)
  currencyConverter = false;

  @JsonProperty('root', String, true)
  root: string = undefined;

  @JsonProperty('columnsMap', [ColumnsPair], true)
  columnsMap: ColumnsPair[] = [];

  @JsonProperty('skipSeparator', String, true)
  skipSeparator = '';

  constructor() {
    this.positions = {};
    this.mandatoryFields = [];
    this.alternativePositions = {};
    this.countryPrices = [];
  }
}
