import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { isNilty } from '@core/utils.service';
import { CustomMetaDataField } from '@models/configuration/custom-metadata-field-model';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AddCustomMetadataFieldModalComponent } from './add-custom-metadata-field/add-custom-metadata-field-modal.component';
import { CustomMetadataService } from './custom-metadata.service';
import { EditCustomMetadataFieldModalComponent } from './edit-custom-metadata-field/edit-custom-metadata-field-modal.component';

@Component({
  selector: 'app-custom-metadata',
  templateUrl: './custom-metadata.component.html',
  styleUrls: ['./custom-metadata.component.scss'],
})
export class CustomMetadataComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  filteredFields: CustomMetaDataField[] = [];
  searchString: string = undefined;

  dataSource: MatTableDataSource<CustomMetaDataField> = new MatTableDataSource();
  displayedColumns = ['mustBeTranslated', 'name', 'label', 'type', 'tags', 'buttons'];

  private allMetadataFields: CustomMetaDataField[] = [];

  constructor(private customMetadataService: CustomMetadataService, private dialog: MatDialog) {}

  ngOnInit() {
    this.reload();

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.search(text);
      });
  }

  new() {
    this.dialog
      .open(AddCustomMetadataFieldModalComponent, { data: this.allMetadataFields, disableClose: true })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.reload();
        }
      });
  }

  search(text: string) {
    this.filteredFields = [...this.allMetadataFields];
    if (!isNilty(text)) {
      this.filteredFields = this.customMetadataService.searchMatchingFields(this.allMetadataFields, text);
    }

    this.dataSource.data = this.filteredFields;
  }

  edit(metadata: CustomMetaDataField) {
    this.dialog
      .open(EditCustomMetadataFieldModalComponent, { data: metadata, disableClose: false })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.reload();
        }
      });
  }

  delete(id: string) {
    this.customMetadataService.delete(id).subscribe(() => this.reload());
  }

  private reload() {
    this.customMetadataService.findAll().subscribe((r) => {
      this.allMetadataFields = r;
      this.dataSource = new MatTableDataSource();
      this.search(this.searchString);
    });
  }
}
