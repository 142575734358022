import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader-state-interface';

@Injectable()
export class LoaderService {
  loaderSubject = new Subject<LoaderState>();

  show() {
    // this.loaderSubject.next({ show: true } as LoaderState);
  }

  hide() {
    this.loaderSubject.next({ show: false } as LoaderState);
  }
}
