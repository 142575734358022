import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from 'src/app/container/suppliers/suppliers.service';
import { MetadataExportService } from '../metadata-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-metadata-export-by-supplier-eans',
  templateUrl: './metadata-export-by-supplier-eans.component.html',
  styleUrls: ['./metadata-export-by-supplier-eans.component.css'],
})
export class MetadataExportBySupplierEansComponent implements OnInit {
  @Input() requestId: string;
  @Input() settingsConfirmed: boolean;
  @Input() editMode: boolean;

  filterForm: FormGroup;
  suppliers: Supplier[];
  selectedSupplier: string = null;

  constructor(
    private suppliersService: SuppliersService,
    private metadataExportService: MetadataExportService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      supplier: new FormControl(),
    });

    this.suppliersService.getAllSuppliers().subscribe((response) => {
      this.suppliers = response;
    });
  }

  exportBySupplier() {
    const supplierCode = this.filterForm.value.supplier;
    this.metadataExportService.exportBySupplier(this.requestId, supplierCode).subscribe(() => {
      this.snackBar.open('Export by supplier request has been sent - You can close this window', 'Close');
    });
  }
}
