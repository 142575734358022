import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZionBrand } from '@models/zion-catalog-item-model';
import { CommonsService } from '@shared/commons.service';
import { MetadataExportService } from '../metadata-export.service';

@Component({
  selector: 'app-metadata-export-by-brand-eans',
  templateUrl: './metadata-export-by-brand-eans.component.html',
  styleUrls: ['./metadata-export-by-brand-eans.component.css'],
})
export class MetadataExportByBrandEansComponent implements OnInit {
  @Input() requestId: string;
  @Input() settingsConfirmed: boolean;
  @Input() editMode: boolean;

  brands: ZionBrand[] = [];
  selectedBrand: number;

  filterForm: FormGroup;

  constructor(private commonService: CommonsService, private metadataExportService: MetadataExportService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      brand: new FormControl(),
    });

    this.commonService.getBrands().subscribe((response) => {
      this.brands = response;
    });
  }

  exportByBrand() {
    this.selectedBrand = this.filterForm.value.brand;
    this.metadataExportService.exportByBrand(this.requestId, this.selectedBrand).subscribe(() => {
      this.snackBar.open('Export by brand request has been sent - You can close this window', 'Close');
    });
  }
}
