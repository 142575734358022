import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConverterService } from '@core/converter.service';
import { isNilty } from '@core/utils.service';
import {
  AbebooksCredentials,
  AmazonSellerCredentials,
  ChannelEngineCredentials,
  SqsNotificationCredentials,
  TikTokCredentials,
  ZalandoCredentials,
} from '@models/configuration/marketplace/credentials/amazon-seller-credentials-model';
import { MarketplaceConnection } from '@models/configuration/marketplace/marketplace-connection-model';
import { StoreService } from '../../../store.service';

@Component({
  selector: 'app-soup-parser-modal',
  templateUrl: './connection-credentials-modal.component.html',
  styleUrls: ['./connection-credentials-modal.component.css'],
})
// TODO -------------------------------------------------------------- HANDLE OTHER TYPES AND ADD SOME VALIDATIONS
export class ConnectionCredentialsModalComponent implements OnInit {
  AMAZON_SELLER_CONNECTION_TYPE = 'AMAZON_SELLER';
  sellerCredentials: AmazonSellerCredentials;

  ABEBOOKS_CONNECTION_TYPE = 'ABEBOOKS';
  abebooksCredentials: AbebooksCredentials;

  VERTICAL_CONNECTION_TYPE = 'VERTICAL';
  sqsCredentials: SqsNotificationCredentials;

  CHANNEL_ENGINE_CONNECTION_TYPE = 'CHANNEL_ENGINE';
  channelEngineCredentials: ChannelEngineCredentials;

  TIKTOK_CONNECTION_TYPE = 'TIKTOK_SHOP';
  tiktokCredentials: TikTokCredentials;

  ZALANDO_CONNECTION_TYPE = 'ZALANDO';
  zalandoCredentials: ZalandoCredentials;

  connectionId: number;
  type: string;

  spApiEndpoints = [
    'https://sellingpartnerapi-na.amazon.com',
    'https://sellingpartnerapi-eu.amazon.com',
    'https://sellingpartnerapi-fe.amazon.com',
  ];

  constructor(
    public dialogRef: MatDialogRef<ConnectionCredentialsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: MarketplaceConnection,
    private snackBar: MatSnackBar,
    private storeService: StoreService,
    private converterService: ConverterService
  ) {}

  ngOnInit(): void {
    this.connectionId = this.data.id;
    this.type = this.data.marketplaceConnectionType;
    this.storeService.findConnectionCredentials(this.connectionId).subscribe((c) => {
      switch (this.type) {
        case this.AMAZON_SELLER_CONNECTION_TYPE: {
          if (!isNilty(c)) {
            this.sellerCredentials = this.converterService.fromJSONtoObj(c, AmazonSellerCredentials);
          } else {
            this.sellerCredentials = new AmazonSellerCredentials();
          }
          break;
        }
        case this.CHANNEL_ENGINE_CONNECTION_TYPE:
          if (!isNilty(c)) {
            this.channelEngineCredentials = this.converterService.fromJSONtoObj(c, ChannelEngineCredentials);
          } else {
            this.channelEngineCredentials = new ChannelEngineCredentials();
          }
          break;
        case this.VERTICAL_CONNECTION_TYPE: {
          if (!isNilty(c)) {
            this.sqsCredentials = this.converterService.fromJSONtoObj(c, SqsNotificationCredentials);
          } else {
            this.sqsCredentials = new SqsNotificationCredentials();
          }
          break;
        }
        case this.ABEBOOKS_CONNECTION_TYPE: {
          if (!isNilty(c)) {
            this.abebooksCredentials = this.converterService.fromJSONtoObj(c, AbebooksCredentials);
          } else {
            this.abebooksCredentials = new AbebooksCredentials();
          }
          break;
        }
        case this.TIKTOK_CONNECTION_TYPE: {
          if (!isNilty(c)) {
            this.tiktokCredentials = this.converterService.fromJSONtoObj(c, TikTokCredentials);
          } else {
            this.tiktokCredentials = new TikTokCredentials();
          }
          break;
        }
        case this.ZALANDO_CONNECTION_TYPE: {
          if (!isNilty(c)) {
            this.zalandoCredentials = this.converterService.fromJSONtoObj(c, ZalandoCredentials);
          } else {
            this.zalandoCredentials = new ZalandoCredentials();
          }
          break;
        }
        default: {
          this.snackBar.open(`Connection type ${this.type} could not be used yet.`)._dismissAfter(3000);
          this.dialogRef.close(null);
          break;
        }
      }
    });
  }

  enablePushNotificationChanged(event: MatCheckboxChange) {
    if (event.checked && isNilty(this.sellerCredentials.sqsNotificationCredentials)) {
      this.sellerCredentials.sqsNotificationCredentials = new SqsNotificationCredentials();
    }
  }

  saveCredentials() {
    let c;
    switch (this.type) {
      case this.AMAZON_SELLER_CONNECTION_TYPE: {
        c = this.sellerCredentials;
        break;
      }
      case this.CHANNEL_ENGINE_CONNECTION_TYPE:
        c = this.channelEngineCredentials;
        break;
      case this.VERTICAL_CONNECTION_TYPE: {
        c = this.sqsCredentials;
        break;
      }
      case this.ABEBOOKS_CONNECTION_TYPE: {
        c = this.abebooksCredentials;
        break;
      }
      case this.TIKTOK_CONNECTION_TYPE: {
        c = this.tiktokCredentials;
        break;
      }
      case this.ZALANDO_CONNECTION_TYPE: {
        c = this.zalandoCredentials;
        break;
      }
      default: {
        break;
      }
    }
    this.storeService.saveConnectionCredentials(this.connectionId, c).subscribe(() => this.dialogRef.close());
  }
}
