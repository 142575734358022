import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { PublicationRulesComponent } from './publication-rules/publication-rules.component';
import { PublicationRulesService } from './publication-rules/publication-rules.service';
import { PublicationRuleEditComponent } from './publication-rules/publication-rule-edit/publication-rule-edit.component';
import { SupplierSourcesComponent } from './publication-rules/publication-rule-edit/rule-sources/supplier-sources/supplier-sources.component';
import { RuleConstraintsComponent } from './publication-rules/publication-rule-edit/rule-constraints/rule-constraints.component';
import { RuleOptionsEditComponent } from './publication-rules/publication-rule-edit/rule-options-edit/rule-options-edit.component';
import { PublicationRulesListComponent } from './publication-rules/publication-rules-list/publication-rules-list.component';
import { RuleProfitsComponent } from './publication-rules/publication-rule-edit/rule-profits/rule-profits.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RuleShipmentClassesComponent } from './publication-rules/publication-rule-edit/rule-shipment-classes/rule-shipment-classes.component';
import { RuleRefreshTriggerModalComponent } from './rule-refresh-trigger-modal/rule-refresh-trigger-modal.component';
import { StockLocationSourcesComponent } from './publication-rules/publication-rule-edit/rule-sources/stock-location-sources/stock-location-sources.component';
import { PowerSellerRulesComponent } from './power-seller-rules/power-seller-rules.component';
import { PowerSellerRulesListComponent } from './power-seller-rules/power-seller-rules-list/power-seller-rules-list.component';
import { PowerSellerRuleEditComponent } from './power-seller-rules/power-seller-rule-edit/power-seller-rule-edit.component';
import { PowerSellerRulesService } from './power-seller-rules/power-seller-rules.service';
import { PowerSellerRuleOptionsEditComponent } from './power-seller-rules/power-seller-rule-edit/power-seller-rule-options-edit/power-seller-rule-options-edit.component';
import { InheritBlacklistsComponent } from './power-seller-rules/power-seller-rule-edit/inherit-blacklists/inherit-blacklists.component';
import { BlacklistsModule } from '../policies/blacklists/blacklists.module';
import { PricingStrategyComponent } from './publication-rules/publication-rule-edit/pricing-strategy/pricing-strategy.component';
import { RuleMetadataComponent } from './publication-rules/publication-rule-edit/rule-metadata/rule-metadata.component';
import { RuleFulfilmentLatenciesComponent } from './publication-rules/publication-rule-edit/rule-fulfilment-latencies/rule-fulfilment-latencies.component';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';

@NgModule({
  declarations: [
    PublicationRulesComponent,
    PublicationRuleEditComponent,
    PublicationRulesListComponent,
    SupplierSourcesComponent,
    StockLocationSourcesComponent,
    RuleConstraintsComponent,
    RuleOptionsEditComponent,
    RuleProfitsComponent,
    RuleFulfilmentLatenciesComponent,
    RuleShipmentClassesComponent,
    RuleRefreshTriggerModalComponent,
    PowerSellerRulesComponent,
    PowerSellerRulesListComponent,
    PowerSellerRuleEditComponent,
    PowerSellerRuleOptionsEditComponent,
    InheritBlacklistsComponent,
    PricingStrategyComponent,
    RuleMetadataComponent,
  ],
  imports: [SharedModule, DragDropModule, BlacklistsModule, MdbTooltipModule],
  providers: [PublicationRulesService, PowerSellerRulesService],
})
export class MarkusRulesModule {}
