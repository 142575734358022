import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductEquivalence } from '@models/product-equivalence-model';
import { ProductMetadataWithAllMetadata } from '@models/product-metadata-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductWithMetadata } from 'src/app/models/product-with-metadata-model';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { ProductFilters } from '@models/filters/product';
import { NetworkCategory } from '@models/network-category-model';
import { NewProductRequest } from '@models/product/new-product-request';
import { ProductWithTitle } from '@models/product/product-with-title-model';

@Injectable()
export class ProductsService {
  resultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  searchBarcode(barcode: string): Observable<ProductWithTitle[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('searchBarcodes') + `?barcode=${barcode}`)
      .pipe(map((r: ProductWithTitle[]) => r.map((it) => this.converter.fromJSONtoObj(it, ProductWithTitle))));
  }

  getFilteredProducts(filters: ProductFilters): Observable<ProductWithMetadata[]> {
    const productBarcodeValue = filters.productBarcodeValue ?? '';
    const productBarcodeType = filters.productBarcodeType?.code ?? '';
    const internalSku = filters.internalSku ?? '';
    const title = filters.title ? filters.title : '';
    const brand = filters.brand ? filters.brand : '';
    const category = filters.category ? filters.category : '';
    const pageIndex = filters.pageNumber ? filters.pageNumber : 0;
    const pageSize = filters.pageSize ? filters.pageSize : 10;
    const sortBy = filters.sortBy ? filters.sortBy : 'orderedOn';
    const sortDirection = filters.sortDirection ? filters.sortDirection : 'desc';

    const queryPath =
      '?productBarcodeValue=' +
      productBarcodeValue +
      '&productBarcodeType=' +
      productBarcodeType +
      '&internalSku=' +
      internalSku +
      '&title=' +
      title +
      '&brand=' +
      brand +
      '&category=' +
      category +
      '&page-index=' +
      pageIndex +
      '&page-size=' +
      pageSize +
      '&sort-by=' +
      sortBy +
      '&sort-direction=' +
      sortDirection;

    return this.http.get(this.environmentService.getRestEndpoint('products') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: ProductWithMetadata[]) => resp.map((r) => this.converter.fromJSONtoObj(r, ProductWithMetadata)))
    );
  }

  getProductMetadataDetails(barcode: string): Observable<ProductMetadataWithAllMetadata> {
    return this.http
      .get(this.environmentService.getRestEndpoint('productMetadataDetails') + barcode)
      .pipe(map((r: ProductMetadataWithAllMetadata) => this.converter.fromJSONtoObj(r, ProductMetadataWithAllMetadata)));
  }

  getProductNetworkCategories(sku: string): Observable<NetworkCategory[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('productNetworkCategories') + sku)
      .pipe(map((r: NetworkCategory[]) => r.map((it) => this.converter.fromJSONtoObj(it, NetworkCategory))));
  }

  saveProductNetworkCategories(sku: string, categories: string[]): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('productNetworkCategories') + sku, categories);
  }

  getAvailableFileTypes(): Observable<any> {
    return this.http.get(this.environmentService.getRestEndpoint('importProductFilesAvailableFileTypes'));
  }

  importProductFilesZip(supplierCode: string, file: File, overrideProductImages: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const path = `${this.environmentService.getRestEndpoint('importProductFilesZip')}${supplierCode}/${overrideProductImages}`;

    return this.http.post(path, formData);
  }

  uploadNetworkCategoriesFile(file: File, network: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const path = this.environmentService.getRestEndpoint('uploadNetworkCategoriesFile') + network;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(path, formData).pipe(map(() => {}));
  }

  addProduct(requestBody: NewProductRequest): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(this.environmentService.getRestEndpoint('addNewProduct'), requestBody).pipe(map(() => {}));
  }

  uploadEquivalenceProducts(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const path = this.environmentService.getRestEndpoint('uploadEquivalenceProductsFile');

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(path, formData).pipe(map(() => {}));
  }

  getFilteredEquivalenceProducts(filters: ProductFilters): Observable<ProductEquivalence[]> {
    const productBarcodeValue = filters.productBarcodeValue ?? '';
    const productBarcodeType = filters.productBarcodeType?.code ?? '';
    const pageIndex = filters.pageNumber ? filters.pageNumber : 0;
    const pageSize = filters.pageSize ? filters.pageSize : 10;
    const sortBy = filters.sortBy ? filters.sortBy : 'orderedOn';
    const sortDirection = filters.sortDirection ? filters.sortDirection : 'desc';

    const queryPath =
      '?productBarcodeValue=' +
      productBarcodeValue +
      '&productBarcodeType=' +
      productBarcodeType +
      '&page-index=' +
      pageIndex +
      '&page-size=' +
      pageSize +
      '&sort-by=' +
      sortBy +
      '&sort-direction=' +
      sortDirection;

    return this.http.get(this.environmentService.getRestEndpoint('productsEquivalence') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: ProductEquivalence[]) => resp.map((r) => this.converter.fromJSONtoObj(r, ProductEquivalence)))
    );
  }

  deleteProductImage(requestBody: { barcode: string; imageUrl: string }): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('deleteImagineUrl'), requestBody);
  }
}
