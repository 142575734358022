import { Component } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MetadataPriorityEntries } from '@models/configuration/metadata-priority-model';
import { ColumnWithPositionAndName } from '@models/export-metadata/metadata-export-model';
import { MetadataExportService } from './metadata-export.service';

@Component({
  selector: 'app-metadata-export',
  templateUrl: './metadata-export.component.html',
  styleUrls: ['./metadata-export.component.scss'],
})
export class MetadataExportComponent {
  metadataPriorityEntries: MetadataPriorityEntries[] = [];
  currentTemplate: ColumnWithPositionAndName[] = [];

  exportMethods = ['UPLOAD FILE', 'EAN', 'EAN BY SUPPLIER', 'EAN BY BRAND'];
  exportMethod: string;

  exportType: string;
  exportFileName = '';
  targetLanguages: string[] = [];
  settingsConfirmed = false;
  editMode = true;

  requestId: string;

  fileUploaded = false;

  constructor(private metadataExportService: MetadataExportService) {}

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  exportMethodSelected(value: string) {
    this.exportMethod = value;
  }

  onExportFileNameChange(fileName: string) {
    this.exportFileName = fileName;
  }

  onTargetLanguagesChange(languages: string[]) {
    this.targetLanguages = languages;
  }

  onExportTypeChange(type: string) {
    this.exportType = type;
  }

  onExitEditMode(edit: boolean) {
    this.editMode = edit;
  }

  onExportSettingsConfirmed(confirmed: boolean) {
    this.settingsConfirmed = confirmed;

    if (this.settingsConfirmed) {
      this.sendExportMetadataRequest();
    }
  }

  sendExportMetadataRequest() {
    this.metadataPriorityEntries.forEach((it, i) => {
      it.priority = i + 1;
    });

    this.metadataExportService
      .requestExport(this.exportType, this.currentTemplate, this.metadataPriorityEntries, this.exportFileName, this.targetLanguages)
      .subscribe((r) => (this.requestId = r));
  }

  checkSourceCompleted() {
    return (
      this.metadataPriorityEntries.length > 0 && this.metadataPriorityEntries.some((it: MetadataPriorityEntries) => it.sourceType !== '')
    );
  }

  checkTemplateCompleted() {
    return this.currentTemplate.length > 0;
  }

  checkDisabled(stepper: MatStepper) {
    switch (stepper.selectedIndex) {
      case 0:
        return !this.checkTemplateCompleted();
      case 1:
        return !this.checkSourceCompleted();
    }
  }
}
