import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { Observable } from 'rxjs';
import { CustomMetaDataField } from '@models/configuration/custom-metadata-field-model';
import { map } from 'rxjs/operators';
import { anyStringInArrayMatches, caseInsensitiveStringMatch } from '@core/utils.service';
import { ColumnWithNameAndTags } from '@models/export-metadata/metadata-export-model';

@Injectable()
export class CustomMetadataService {
  constructor(private environmentService: EnvironmentService, private http: HttpClient, private converter: ConverterService) {}

  searchMatchingFields(allFields: CustomMetaDataField[], searchString: string): CustomMetaDataField[] {
    return [
      ...allFields.filter(
        (it) =>
          caseInsensitiveStringMatch(it.name, searchString) ||
          caseInsensitiveStringMatch(it.label, searchString) ||
          anyStringInArrayMatches(it.tags, searchString)
      ),
    ];
  }

  findAll(): Observable<CustomMetaDataField[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('customMetadataFields') + '/all')
      .pipe(map((data: CustomMetaDataField[]) => data.map((it) => this.converter.fromJSONtoObj(it, CustomMetaDataField))));
  }

  findAllMappingFields(): Observable<ColumnWithNameAndTags[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('metadataMappingFields'))
      .pipe(map((r: ColumnWithNameAndTags[]) => r.map((it) => this.converter.fromJSONtoObj(it, ColumnWithNameAndTags))));
  }

  save(f: CustomMetaDataField): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('customMetadataFields'), this.converter.fromObjtoJSON(f));
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('customMetadataFields') + '/' + id);
  }
}
