import { Component, Input, OnInit } from '@angular/core';
import { ColumnsPair, FileProperties, CountryPrice } from '../../../../../../models/configuration/soup-configuration/file-properties-model';
import { SoupConfigurationService } from '../../../soup-configuration.service';
import { MatSelectChange } from '@angular/material/select';
import { isNilty } from '../../../../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { ColumnsMapperModalComponent } from './columns-mapper-modal/columns-mapper-modal.component';
import { GenericConfirmationModalComponent } from '../../../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { CountryPriceModalComponent } from './country-prices-modal/country-price-modal.component';

@Component({
  selector: 'app-file-properties',
  templateUrl: './file-properties.component.html',
  styleUrls: ['./file-properties.component.css'],
})
export class FilePropertiesComponent implements OnInit {
  @Input() fileTypes: string[];

  validFields: string[];
  fileProperties: FileProperties;
  availableMandatoryFields: string[];

  constructor(private soupConfigurationService: SoupConfigurationService, private dialog: MatDialog) {}

  @Input() set _fileProperties(fp: FileProperties) {
    this.fileProperties = fp;
  }

  ngOnInit(): void {
    this.soupConfigurationService.findValidFields().subscribe((resp: string[]) => (this.validFields = resp));
  }

  // Methods for Mandatory Fields
  removeMandatoryField(index: number) {
    this.fileProperties.mandatoryFields.splice(index, 1);
  }

  addMandatoryField() {
    this.availableMandatoryFields = Object.keys(this.fileProperties.positions).filter(
      (it) => this.fileProperties.mandatoryFields.findIndex((f) => f === it) === -1
    );
  }

  selectedMandatoryField(event: MatSelectChange) {
    this.fileProperties.mandatoryFields.push(event.value);
    this.availableMandatoryFields = undefined;
  }

  // Methods for Columns Map
  createColumnsMap() {
    if (isNilty(this.fileProperties.columnsMap)) {
      this.fileProperties.columnsMap = [];
    }
    this.editColumnsPair(new ColumnsPair());
  }

  editColumnsPair(cp: ColumnsPair, index: number = -1) {
    this.dialog
      .open(ColumnsMapperModalComponent, { data: { columns: cp, jsonFields: this.validFields }, disableClose: true })
      .afterClosed()
      .subscribe((resp: ColumnsPair) => {
        if (!isNilty(resp)) {
          if (index !== -1) {
            this.fileProperties.columnsMap.splice(index, 1);
          }
          this.fileProperties.columnsMap.push(resp);
        }
      });
  }

  removeColumnsPair(cp: ColumnsPair, index: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: `Deleting ${cp.fromColumn}-${cp.toColumn} mapping?` })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.fileProperties.columnsMap.splice(index, 1);
        }
      });
  }

  // Methods for Country Prices
  addCountryPrice() {
    const newCountryPrice: CountryPrice = {
      countryCode: '',
      fields: {
        SHIPPING_COST: '',
        CURRENCY: '',
        LATENCY: '',
        PRICE: '',
      },
    };
    this.editCountryPrice(newCountryPrice);
  }

  editCountryPrice(cp: CountryPrice, index: number = -1) {
    this.dialog
      .open(CountryPriceModalComponent, { data: { countryPrice: cp }, disableClose: true })
      .afterClosed()
      .subscribe((resp: CountryPrice) => {
        if (!isNilty(resp)) {
          // Filtra i valori vuoti o non valorizzati nel campo 'fields'
          Object.keys(resp.fields).forEach((key) => {
            if (resp.fields[key] === null || resp.fields[key] === '') {
              delete resp.fields[key];
            }
          });

          if (index !== -1) {
            this.fileProperties.countryPrices[index] = resp;
          } else {
            this.fileProperties.countryPrices.push(resp);
          }
        }
      });
  }

  removeCountryPrice(index: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'Are you sure you want to delete this country price?' })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.fileProperties.countryPrices.splice(index, 1);
        }
      });
  }
}
