import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MyDateAdapter } from '@core/my-date-adapter.component';
import { RESTInterceptor } from '@core/rest-interceptor';
import { BrandonAutocompleteComponent } from '@shared/brandon-autocomplete/brandon-autocomplete.component';
import { TemplateRenameModalComponent } from '@shared/export-template-name-modal/export-template-name-modal.component';
import { GenericAutocompleteModalComponent } from '@shared/generic-autocomplete-modal/generic-autocomplete-modal.component';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';
import { HelpModalComponent } from '@shared/help-modal/help-modal.component';
import { OwnerIconComponent } from '@shared/owner-icon/owner-icon.component';
import { MyPipes } from '@shared/pipes/my-pipes.module';
import { ProductNetworkCategoryModalComponent } from '@shared/product-network-category-modal/product-network-category-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { PricesModalComponent } from './container/catalog-items/catalog-items-list/price-modal/prices-modal.component';
import { PredicatesEditComponent } from './container/configuration/markus-rules/publication-rules/publication-rule-edit/predicates-edit/predicates-edit.component';
import { PolicyTargetTypeIconComponent } from './container/configuration/policies/shared/policy-target-type-icon.component';
import { PublicationRefreshModalComponent } from './container/publications/shared/publications-list/publication-edit-button/publication-refresh-modal/publication-refresh-modal.component';
import { MaterialModule } from './material.module';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@NgModule({
  declarations: [
    GenericConfirmationModalComponent,
    GenericErrorModalComponent,
    ProductNetworkCategoryModalComponent,
    PredicatesEditComponent,
    HelpModalComponent,
    BrandonAutocompleteComponent,
    PolicyTargetTypeIconComponent,
    PublicationRefreshModalComponent,
    GenericAutocompleteModalComponent,
    OwnerIconComponent,
    PricesModalComponent,
    TemplateRenameModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    OverlayModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MyPipes,
    AgGridModule,
    DragDropModule,
  ],
  providers: [
    MyPipes,
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: RESTInterceptor, multi: true },
  ],
  exports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MyPipes,
    AgGridModule,
    PredicatesEditComponent,
    BrandonAutocompleteComponent,
    PolicyTargetTypeIconComponent,
    OwnerIconComponent,
    PricesModalComponent,
  ],
})
export class SharedModule {}
