import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/environment/environment.service';
import { MetadataPriorityEntries } from '@models/configuration/metadata-priority-model';
import { ColumnWithNameAndTags, ColumnWithPositionAndName, ExportMetadataTemplate } from '@models/export-metadata/metadata-export-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MetadataExportService {
  constructor(private environmentService: EnvironmentService, private http: HttpClient) {}

  getColumns(): Observable<ColumnWithNameAndTags[]> {
    const path = this.environmentService.getRestEndpoint('metadataExport') + 'columns';
    return this.http.get<ColumnWithNameAndTags[]>(path);
  }

  getTemplates(): Observable<ExportMetadataTemplate[]> {
    const path = this.environmentService.getRestEndpoint('metadataExport') + 'templates';
    return this.http.get<ExportMetadataTemplate[]>(path);
  }

  saveTemplate(template: ExportMetadataTemplate): Observable<ExportMetadataTemplate> {
    const path = this.environmentService.getRestEndpoint('metadataExport') + 'templates/save';
    return this.http.post<ExportMetadataTemplate>(path, template);
  }

  renameTemplate(id: string, newName: string): Observable<ExportMetadataTemplate> {
    const path = this.environmentService.getRestEndpoint('metadataExport') + 'templates/rename/' + id;
    return this.http.put<ExportMetadataTemplate>(path, newName);
  }

  deleteTemplate(template: ExportMetadataTemplate): Observable<ExportMetadataTemplate> {
    const path = this.environmentService.getRestEndpoint('metadataExport') + 'templates/delete';
    return this.http.delete<ExportMetadataTemplate>(path, { body: template });
  }

  getRuleTypes(): Observable<string[]> {
    const path = this.environmentService.getRestEndpoint('metadataExport') + 'ruleTypes';
    return this.http.get<string[]>(path);
  }

  requestExport(
    exportType: string,
    columns: ColumnWithPositionAndName[],
    sources: MetadataPriorityEntries[],
    exportFileName: string = '',
    targetLanguages: string[] = []
  ): Observable<string> {
    const body = {
      columns,
      exportType,
      sources,
      exportFileName,
      targetLanguages,
    };

    return this.http
      .post(this.environmentService.getRestEndpoint('metadataExport') + 'request-export-metadata', body)
      .pipe(map((r: { requestId: string }) => r.requestId));
  }

  uploadFile(requestId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('metadataExport') + 'export-metadata-from-file/' + requestId, formData);
  }

  exportByEans(requestId: string, eans: string[]): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('metadataExport') + 'export-metadata-from-eans/' + requestId, eans);
  }

  exportBySupplier(requestId: string, supplierCode: string): Observable<any> {
    return this.http.post(
      this.environmentService.getRestEndpoint('metadataExport') + 'export-metadata-from-supplier/' + requestId,
      supplierCode
    );
  }

  exportByBrand(requestId: string, brandId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('metadataExport') + 'export-metadata-from-brand/' + requestId, brandId);
  }
}
