import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericImportTemplate, ImportTemplates, TemplateColumn, TemplateColumnWithPosition } from '@models/import/import-templates-model';
import { caseInsensitiveStringMatch, anyStringInArrayMatches } from '@core/utils.service';

@Injectable()
export class GenericImportService {
  constructor(private environmentService: EnvironmentService, private http: HttpClient, private converter: ConverterService) {}

  searchMatchingFields(allFields: TemplateColumn[], searchString: string): TemplateColumn[] {
    return [
      ...allFields.filter(
        (it) =>
          caseInsensitiveStringMatch(it.name, searchString) ||
          caseInsensitiveStringMatch(it.description, searchString) ||
          anyStringInArrayMatches(it.tags, searchString)
      ),
    ];
  }

  getTemplates(): Observable<ImportTemplates[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('importTemplates'))
      .pipe(map((r: ImportTemplates[]) => r.map((it) => this.converter.fromJSONtoObj(it, ImportTemplates))));
  }

  saveTemplate(t: GenericImportTemplate): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('importTemplates'), t);
  }

  renameTemplate(id: string, newName: string): Observable<any> {
    const path = this.environmentService.getRestEndpoint('importTemplates') + '/rename/' + id;
    return this.http.put<any>(path, newName);
  }

  deleteTemplate(t: GenericImportTemplate): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('importTemplates') + '/' + t.id);
  }

  requestImport(
    importType: string,
    fileFormat: 'EXCEL' | 'CSV',
    columns: TemplateColumnWithPosition[],
    sourceType: string,
    sourceId: string,
    language: string,
    requestedTranslations: string[],
    translatorAgent: string
  ): Observable<string> {
    const body = columns.map((it) => ({ column: it.column.name, position: it.position, mandatory: it.mandatory }));

    const path =
      this.environmentService.getRestEndpoint('requestImport') +
      importType +
      '?sourceType=' +
      (sourceType ? sourceType : '') +
      '&sourceId=' +
      sourceId +
      '&fileFormat=' +
      fileFormat +
      '&language=' +
      language +
      '&translations=' +
      requestedTranslations +
      '&translatorAgent=' +
      translatorAgent;

    return this.http.post(path, body).pipe(
      map((r: { requestId: string }) => {
        return r.requestId;
      })
    );
  }

  uploadFile(requestId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('uploadImportFile') + requestId, formData);
  }

  getsourceTypes(): Observable<string[]> {
    const path = this.environmentService.getRestEndpoint('sourceTypes') + 'generic-import';
    return this.http.get<string[]>(path);
  }
}
