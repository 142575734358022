import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PromoPrice, PromoPriceFilters } from '@models/promo-price-model';
import { PromoPriceService } from './promo-price.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNilty } from '@core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-promo-price',
  templateUrl: './promo-price.component.html',
  styleUrls: ['./promo-price.component.css'],
})
export class PromoPriceComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('promoPriceFile', { static: false }) promoPriceFile: ElementRef;

  currentFileUpload: File;

  subscription: Subscription;

  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<PromoPrice>([]);
  displayedColumns = ['storeCode', 'barcode', 'price', 'startDate', 'endDate', 'fileUrl', 'loadingDate', 'email', 'deletePromoPrice'];

  constructor(private promoPriceService: PromoPriceService, private snackbar: MatSnackBar, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.resultsNumber = this.promoPriceService.resultsNumber;

    if (isNilty(this.promoPriceService.promoPriceFilters)) {
      this.promoPriceService.promoPriceFilters = new PromoPriceFilters();
    }

    this.getPromoPrices();
  }

  getPromoPrices() {
    this.promoPriceService.getPromoPricesByFilters().subscribe((resp: PromoPrice[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.promoPriceService.promoPriceFilters.pageNumber = this.paginator.pageIndex;
    this.promoPriceService.promoPriceFilters.pageSize = this.paginator.pageSize;
    this.getPromoPrices();
  }

  onSort() {
    this.promoPriceService.promoPriceFilters.sortBy = this.sort.active;
    this.promoPriceService.promoPriceFilters.sortDirection = this.sort.direction;
    this.getPromoPrices();
  }

  applyFilter(filters: PromoPriceFilters) {
    this.promoPriceService.promoPriceFilters = filters;
    this.paginator.pageIndex = 0;
    this.getPromoPrices();
  }

  uploadFile() {
    const message = `------------------- UPLOADING PROMO PRICE FILE -------------------\n
    The file must be a CSV file with the following columns:\n
    storeCode, barcode, price, currency, startDate, endDate (dd/MM/yyyy)\n`;

    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: message,
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.promoPriceFile.nativeElement.click();
        }
      });
  }

  fileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    if (this.currentFileUpload.type !== 'csv') {
      this.snackbar.open('Only CSV files are allowed', 'Close', { duration: 2000 });
    }
    this.promoPriceService.uploadPromoPriceFile(this.currentFileUpload).subscribe(() => {
      // Do nothing
    });
  }

  onDelete(promoPrice: PromoPrice) {
    const message = `DELETING PROMO PRICE\n
    Store: ${promoPrice.storeCode} - Ean: ${promoPrice.barcode}
    Range date: ${promoPrice.startDate} - ${promoPrice.endDate}\n`;

    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: message,
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.promoPriceService.deletePromoPrice(promoPrice.id).subscribe(() => {
            this.getPromoPrices();
          });
        }
      });
  }
}
