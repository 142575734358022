import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseEntity } from './shared/base-entity-model';
import { Owner } from './configuration/marketplace/account-model';

@JsonObject('Supplier')
export class Supplier extends BaseEntity {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('businessName', String, true) businessName: string = undefined;
  @JsonProperty('defaultDiscountPercentage', Number, true) defaultDiscountPercentage: number = undefined;
  @JsonProperty('overrideFeedDiscount', Boolean, true) overrideFeedDiscount: boolean = undefined;
  @JsonProperty('insertNewProducts', Boolean, true) insertNewProducts: boolean = undefined;
  @JsonProperty('sendsFullCatalog', Boolean, true) sendsFullCatalog: boolean = undefined;
  @JsonProperty('lastStockUpdate', String, true) lastStockUpdate: string = undefined;
  @JsonProperty('catalogItemHistorySize', Number, true) catalogItemHistorySize: number = undefined;
  @JsonProperty('requestsMetadata', Boolean, true) requestsMetadata: boolean = undefined;
  @JsonProperty('requestsBookMetadata', Boolean, true) requestsBookMetadata: boolean = undefined;
  @JsonProperty('mdActive', Boolean, true) mdActive = false;
  @JsonProperty('mdDefaultQuantity', Number, true) mdDefaultQuantity: number = undefined;
  @JsonProperty('defaultQuantity', Number, true) defaultQuantity: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('salesRightExcludeToFind', String, true) salesRightExcludeToFind: string = undefined;
  @JsonProperty('salesRightExcludeToRemove', [String], true) salesRightExcludeToRemove: string[] = undefined;
  @JsonProperty('salesRightExcludeOnlyToRemove', [String], true) salesRightExcludeOnlyToRemove: string[] = undefined;

  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('poShipmentCost', Number, true) poShipmentCost: number = undefined;
  @JsonProperty('dropShipper', Boolean, true) dropShipper: boolean = undefined;
  @JsonProperty('trackPublicationMetrics', Boolean, true) trackPublicationMetrics: boolean = undefined;

  @JsonProperty('defaultCountryOfOrigin', String, true) defaultCountryOfOrigin: string = undefined;
  @JsonProperty('updateProductRestrictions', Boolean, true) updateProductRestrictions: boolean = undefined;
  @JsonProperty('hasOwnRestrictions', Boolean, true) hasOwnRestrictions: boolean = undefined;

  @JsonProperty('poShipmentCostForKg', Number, true) poShipmentCostForKg: number = undefined;
  @JsonProperty('description', String, true) description: string;
  initDescription() {
    this.description = this.code + ' - ' + this.name;
  }
}

@JsonObject('SupplierDiscountCode')
export class SupplierDiscountCode extends BaseEntity {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('discountCode', String, true) discountCode: string = undefined;
  @JsonProperty('discountPercentage', Number, true) discountPercentage: number = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
}

@JsonObject('InvoiceTo')
export class InvoiceTo extends BaseEntity {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('owner', Owner, true) owner: string = undefined;
  @JsonProperty('destinationCountryCode', String, true) destinationCountryCode: string = undefined;
  @JsonProperty('invoiceTo', String, true) invoiceTo: string = undefined;
  @JsonProperty('billingName', String, true) billingName: string = undefined;
}
