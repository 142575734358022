import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PublicationRule } from '@models/configuration/rules/publication-rule/publication-rule-model';
import { CustomMetadataService } from '../../../../catalog-import-export/custom-metadata/custom-metadata.service';
import { CommonsService } from '@shared/commons.service';
import { Language } from '@models/language-model';
import { ColumnWithNameAndTags, filterColumnWithNameAndTag } from '@models/export-metadata/metadata-export-model';
import { isNilty } from '@core/utils.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-rule-metadata',
  templateUrl: './rule-metadata.component.html',
  styleUrls: ['./rule-metadata.component.scss'],
})
export class RuleMetadataComponent implements OnInit {
  @ViewChild('languageSelect') languageSelect: MatSelect;

  @Input() rule: PublicationRule;

  languages: Language[];
  selectedLanguages: Language[] = [];
  allMetadataFields: ColumnWithNameAndTags[];
  filteredMetadataFields: ColumnWithNameAndTags[];

  fieldSearchText = '';

  constructor(customMetadataService: CustomMetadataService, commonService: CommonsService) {
    commonService.getLanguages().subscribe((l) => {
      this.languages = l;
      this.initSelectedLanguages();
    });
    customMetadataService.findAllMappingFields().subscribe((m) => {
      this.allMetadataFields = m;
      this.filterFields('');
    });
  }

  ngOnInit() {
    this.initSelectedLanguages();
  }

  addLanguage(change: MatSelectChange) {
    const addedLanguage = change.value;
    const alreadySelected = this.selectedLanguages.find((l) => l.code === addedLanguage.code);
    this.languageSelect.value = undefined;
    if (alreadySelected) {
      return;
    }
    this.selectedLanguages.push(addedLanguage);
    this.updateRule();
  }
  removeLanguage(index: number) {
    this.selectedLanguages.splice(index, 1);
    this.updateRule();
  }

  addField(f: ColumnWithNameAndTags) {
    if (isNilty(this.rule.metadataFields)) this.rule.metadataFields = [];
    this.rule.metadataFields.push(f.name);
    this.rule.metadataFields = this.rule.metadataFields.sort((a, b) => (a > b ? 1 : -1));
    this.filterFields(this.fieldSearchText);
  }
  removeField(index: number) {
    this.rule.metadataFields.splice(index, 1);
    this.filterFields(this.fieldSearchText);
  }

  filterFields(term: string) {
    this.filteredMetadataFields = filterColumnWithNameAndTag(this.allMetadataFields, this.rule.metadataFields, term).slice(0, 20);
  }

  private initSelectedLanguages() {
    if (!isNilty(this.rule?.metadataLanguageCodes) && !isNilty(this.languages)) {
      this.selectedLanguages = this.rule.metadataLanguageCodes.map((languageCode) => this.languages.find((l) => l.code === languageCode));
    }
  }

  private updateRule() {
    this.rule.metadataLanguageCodes = this.selectedLanguages.map((it) => it.code);
  }
}
