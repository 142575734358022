import { Component, EventEmitter, Output } from '@angular/core';
import { isEqual } from '@core/utils.service';
import { Language } from '@models/language-model';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-metadata-export-file-settings',
  templateUrl: './metadata-export-file-settings.component.html',
  styleUrls: ['./metadata-export-file-settings.component.css'],
})
export class MetadataExportFileSettingsComponent {
  @Output() exportFileNameChange = new EventEmitter<string>();
  @Output() targetLanguagesChange = new EventEmitter<string[]>();
  @Output() exportTypeChange = new EventEmitter<string>();
  @Output() exportSettingsConfirmed = new EventEmitter<boolean>();
  @Output() exitEditModeEvent = new EventEmitter<boolean>();

  exportFileName = '';
  oldFilename = '';

  languages: Language[] = [];

  targetLanguages: string[] = ['EN'];
  oldTargetLanguages: string[] = [];

  exportType: string;
  oldExportType = '';

  settingsConfirmed = false;
  editButtonDisabled = true;
  editMode = false;

  equals = true;

  constructor(commonsService: CommonsService) {
    commonsService.getLanguages().subscribe((r) => (this.languages = r));
  }

  fileNameChanged(event) {
    this.exportFileName = event.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9-_.]/g, '');
  }

  changeTargetLanguage(event) {
    if (this.editMode) {
      this.equals = isEqual(event, this.oldTargetLanguages);
    }
  }

  confirmSettings() {
    this.settingsConfirmed = true;
    this.editButtonDisabled = false;
    this.editMode = false;

    if (this.checkEditChanges()) {
      this.emitAllSettings();
    }
  }

  emitAllSettings() {
    this.exportFileNameChange.emit(this.exportFileName);
    this.targetLanguagesChange.emit(this.targetLanguages);
    this.exportTypeChange.emit(this.exportType);
    this.exitEditModeEvent.emit(false);
    this.exportSettingsConfirmed.emit(true);
  }

  editSettings() {
    this.settingsConfirmed = false;
    this.editButtonDisabled = true;
    this.editMode = true;

    this.saveOldSettings();
    this.equals = true;

    this.exitEditModeEvent.emit(true);
  }

  saveOldSettings() {
    this.oldFilename = this.exportFileName;
    this.oldExportType = this.exportType;
    this.oldTargetLanguages = [...this.targetLanguages];
  }

  checkEditChanges() {
    return (
      this.exportFileName !== this.oldFilename ||
      this.exportType !== this.oldExportType ||
      !isEqual(this.targetLanguages, this.oldTargetLanguages)
    );
  }

  exitEditMode() {
    this.exportFileName = this.oldFilename;
    this.exportType = this.oldExportType;
    this.targetLanguages = this.oldTargetLanguages;
    this.editMode = false;
    this.settingsConfirmed = true;
    this.editButtonDisabled = false;
    this.exitEditModeEvent.emit(false);
  }
}
