import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TemplateColumn')
export class TemplateColumn {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('tags', [String], true) tags: string[] = undefined;
}

@JsonObject('TemplateColumnWithPosition')
export class TemplateColumnWithPosition {
  @JsonProperty('column', TemplateColumn, true) column: TemplateColumn = undefined;
  @JsonProperty('position', String, true) position: string = undefined;
  // Mandatory in the current template
  @JsonProperty('mandatory', Boolean, true) mandatory: boolean = undefined;

  // Always mandatory for current importType
  mandatoryForImportType: boolean;

  constructor(column: TemplateColumn, position: string, mandatory: boolean, mandatoryForImportType: boolean) {
    this.column = column;
    this.position = position;
    this.mandatory = mandatory;
    this.mandatoryForImportType = mandatoryForImportType;
  }
}

@JsonObject('GenericImportTemplate')
export class GenericImportTemplate {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('templateName', String, true) templateName: string = undefined;
  @JsonProperty('importType', String, true) importType: string = undefined;
  @JsonProperty('columns', [TemplateColumnWithPosition], true) columns: TemplateColumnWithPosition[] = undefined;
  @JsonProperty('editable', Boolean, true) editable: boolean = undefined;
}

export const buildEmptyImportTemplate = (templateType: ImportTemplates): GenericImportTemplate => {
  const t = new GenericImportTemplate();
  const mandatoryColumns = templateType.mandatoryColumnsForImportType.map((c) => new TemplateColumnWithPosition(c, '', true, true));
  t.importType = templateType.importType;
  t.columns = mandatoryColumns;
  t.editable = true;

  return t;
};

export const duplicateImportTemplate = (t: GenericImportTemplate, templateType: ImportTemplates): GenericImportTemplate => {
  const columns = t.columns.map((column) => ({
    ...column,
    mandatoryForImportType: templateType.mandatoryColumnsForImportType.findIndex((it) => it.name === column.column.name) !== -1,
  }));

  return { ...t, columns };
};

@JsonObject('ImportTemplates')
export class ImportTemplates {
  @JsonProperty('importType', String, true) importType: string = undefined;
  @JsonProperty('mandatoryColumnsForImportType', [TemplateColumn], true) mandatoryColumnsForImportType: TemplateColumn[] = [];
  @JsonProperty('availableColumns', [TemplateColumn], true) availableColumns: TemplateColumn[] = [];
  @JsonProperty('templates', [GenericImportTemplate], true) templates: GenericImportTemplate[] = undefined;
  @JsonProperty('allowUserTemplates', Boolean, true) allowUserTemplates: boolean = undefined;
  @JsonProperty('tags', [String], true) tags: string[] = [];
}
